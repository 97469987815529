import React, { useMemo, useState, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { API_URL, GATSBY_SIGNATURE } from '@/npc-api/config'
import toast from 'react-hot-toast'
import { navigate } from 'gatsby'
import { jobsProp } from '../JobList'
import { useTranslation } from 'react-i18next'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'
type props = {
  job: jobsProp | undefined
}

const JobDetailsP: React.FC<props> = ({ job }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [midName, setmidName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [linkedInUrl, setLink] = useState('')
  const [experience, setTexp] = useState('')
  const [relevantExperience, setRexp] = useState('')
  const [leavingReason, setReason] = useState('')
  const { t } = useTranslation()

  const urlLinkedinInputRef = useRef<HTMLInputElement>(null)
  const leavingreasonRef = useRef<HTMLTextAreaElement>(null)

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * 10,
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
    <li
      className="error-form-rejected"
      key={file.path}
      style={{ marginTop: '10px', width: '100%' }}
    >
      {file.path}
      <ul className="error-form-rejected">
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))
  const dropClasses = useMemo(
    () => `inputFile ${fileRejectionItems.length ? 'error-input' : ''}`,
    [fileRejectionItems],
  )

  const files = acceptedFiles.map((file: any) => (
    <span style={{ color: '#0E589B' }} key={file.path}>
      {file.path}
    </span>
  ))

  // validate email
  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // validate url
  const validateUrl = (url: string) => {
    const re =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    return re.test(String(url).toLowerCase())
  }

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault()
      if (!validateUrl(linkedInUrl)) {
        toast.error('Please enter a valid url')
        if (urlLinkedinInputRef.current) {
          urlLinkedinInputRef.current.focus()
          urlLinkedinInputRef.current.style.borderColor = 'red'
        }
      } else if (acceptedFiles.length === 0 || fileRejections.length > 0) {
        toast.error('Please select a valid file')
      } else if (!name) {
        toast.error('Please enter a name')
      } else if (!phone) {
        toast.error('Please enter a phone')
      } else if (!leavingReason) {
        toast.error('Please enter a leaving reason')
        if (leavingreasonRef.current) {
          leavingreasonRef.current.focus()
          leavingreasonRef.current.style.borderColor = 'red'
        }
      } else {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('name', name)
        formData.append('midName', midName)
        formData.append('lastName', lastName)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('linkedInUrl', linkedInUrl)
        formData.append('experience', experience)
        formData.append('relevantExperience', relevantExperience)
        formData.append('leavingReason', leavingReason)
        formData.append('jobRoleId', job && job.id ? job.id.toString() : '')
        formData.append('resume', acceptedFiles[0])
        formData.append('signature', GATSBY_SIGNATURE)

        GTM.sendEvent({
          event: GTMEventType.RequestJob,
          job_id: job?.id ?? '',
          job_name: job?.name ?? '',
          job_location: job?.location ?? '',
          job_type: job?.jobType ?? '',
        })

        await axios.post(`${API_URL}/job-applies/apply`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        GTM.sendEvent({
          event: GTMEventType.SuccessJobRequest,
          job_id: job?.id ?? '',
          job_name: job?.name ?? '',
          job_location: job?.location ?? '',
          job_type: job?.jobType ?? '',
        })

        setIsLoading(false)
        toast.success('We recieved your request!! Our team will reach you.')
        navigate('/careers')
      }
      return
    } catch (error) {
      GTM.sendEvent({
        event: GTMEventType.FailedJobRequest,
        job_id: job?.id ?? '',
        job_name: job?.name ?? '',
        job_location: job?.location ?? '',
        job_type: job?.jobType ?? '',
      })

      setIsLoading(false)
      toast.error('Error submiting')
    }
  }

  return (
    <section className="job-details-section">
      <div className="container">
        <div className="job-detail-wrapper">
          <div className="info-job-detail-wrapper">
            <h5>Job Summary</h5>
            <p className="summary-detail">{job && job.description}</p>
            <h5>Key Duties / Responsibilities / Accountabilities</h5>
            <div className="container">
              <ul>{job && job.keyDuties?.keyDuties.map((e, kd) => <li key={kd}>{e}</li>)} </ul>
            </div>
            <h5>Duties, Required Knowledge, Skills and Abilities:</h5>
            <div className="container">
              <ul>{job && job.duties?.duties.map((e, kd) => <li key={kd}>{e}</li>)}</ul>
            </div>
            <br />
            <h5>Qualifications</h5>
            <div className="qualifications-details">
              <h6>Minimum Qualifications</h6>
              <div className="container">
                <ul>
                  {job &&
                    job.minQualifications?.minQualifications.map((e, kd) => <li key={kd}>{e}</li>)}
                </ul>
              </div>

              <h6>Preferred qualifications</h6>
              <div className="container">
                <ul>
                  {job &&
                    job.preferedQualifications?.preferedQualifications.map((e, kd) => (
                      <li key={kd}>{e}</li>
                    ))}
                </ul>
              </div>
            </div>

            <h5>Environment and Cognitive / Physical Demands</h5>
            <div className="qualifications-details">
              <h6>Environment And Cognitive</h6>
              <div className="container">
                <ul>{job && job.enviroment?.enviroment.map((e, kd) => <li key={kd}>{e}</li>)}</ul>
              </div>
              <h6>Physical demands</h6>
              <div className="container">
                <ul>
                  {job &&
                    job.physicalDemands?.physicalDemands.map((e, kd) => <li key={kd}>{e}</li>)}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="apply-wrapper">
          <div>
            <div className="title-wp">
              <h5>{t('applicationForm.title')}</h5>
              <p>{t('applicationForm.attachFile.subTitle')}</p>
            </div>

            <div {...getRootProps({ className: `${dropClasses} upload-cv-space` })}>
              <input {...getInputProps()} name="resume" />
              <img src="/images/upload.svg" alt="img" />
              <div className="attach-info">
                <h5 className="attach-title">{t('applicationForm.attachFile.label')}</h5>
                <p>{t('applicationForm.attachFile.formats')}</p>
              </div>
            </div>
            <div className="selected-file" style={{ width: '100%' }}>
              {files}
            </div>
            <div style={{ color: 'red', width: '100%', overflow: 'hidden' }}>
              {fileRejectionItems}
            </div>
          </div>

          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="First Name">{t('applicationForm.firstName')}</label>
              <input
                className="form-control"
                id="First Name"
                autoComplete="off"
                type="text"
                value={name}
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="MiddleName">{t('applicationForm.middleName')}</label>
              <input
                className="form-control"
                id="MiddleName"
                autoComplete="off"
                type="text"
                value={midName}
                name="midName"
                onChange={(e) => setmidName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Last Name">{t('applicationForm.lastName')}</label>
              <input
                className="form-control col"
                id="Last Name"
                autoComplete="off"
                type="text"
                value={lastName}
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Email">{t('applicationForm.email')}</label>
              <input
                className="form-control"
                id="Email"
                autoComplete="off"
                type="email"
                value={email}
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Phone Number">{t('applicationForm.phoneNumber')}</label>
              <input
                className="form-control"
                id="Phone Number"
                autoComplete="off"
                type="text"
                value={phone}
                name="phone"
                required
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <h5 className="linkedin-title">{t('applicationForm.linkedin&experience')}</h5>
            <div className="form-group mb-0">
              <label htmlFor="Linkedin URL">{t('applicationForm.linkedinUrl')}</label>
              <input
                ref={urlLinkedinInputRef}
                className="form-control"
                id="Linkedin URL"
                autoComplete="off"
                type="text"
                value={linkedInUrl}
                name="linkedInUrl"
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div className="row m-0">
              <div className="form-group col p-0 mb-0" style={{ marginRight: '14px' }}>
                <label htmlFor="Total Experience (in years)">
                  {t('applicationForm.totalExperience')} <br />({t('applicationForm.inYears')})
                </label>
                <input
                  className="form-control"
                  id="Total Experience (in years)"
                  autoComplete="off"
                  type="number"
                  value={experience}
                  name="experience"
                  onChange={(e) => setTexp(e.target.value)}
                />
              </div>
              <div className="form-group col p-0 mb-0">
                <label htmlFor="Total Experience (in years)">
                  {t('applicationForm.relevantExperience')} <br />({t('applicationForm.inYears')})
                </label>
                <input
                  className="form-control"
                  autoComplete="off"
                  type="number"
                  value={relevantExperience}
                  name="relevantExperience"
                  onChange={(e) => setRexp(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="lrs">
                {t('applicationForm.reasonOfLeavingPreviousOrganization')}
              </label>
              <textarea
                ref={leavingreasonRef}
                rows={5}
                cols={5}
                className="form-control"
                id="lrs"
                autoComplete="off"
                value={leavingReason}
                name="leavingReason"
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
            <button disabled={isLoading} type="submit" className="btn-submit-cv">
              {isLoading ? `${t('applicationForm.submitting')}...` : t('applicationForm.apply')}
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default JobDetailsP
