import React from 'react'
import Layout from '@/components/Layout'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import JobDetails from '@/components/Career/Details'
import Header from '@/components/Header'
import DetailsHeader from '@/components/Career/Details/Header'
import Subscribe from '@/components/Career/Subscribe'
import Contact from '@/components/Home/Contact'
import { useViewport } from '@/hooks/useViewport'
import { graphql } from 'gatsby'

const JobDetailsPage = ({ pageContext }: any) => {
  const { jobRole } = pageContext
  const { isMobile } = useViewport()

  return (
    <Layout>
      <SEO title="Job Details" canonical={useLocation().host} />
      <Header />
      <DetailsHeader
        name={jobRole ? jobRole.name : ''}
        location={jobRole ? jobRole.location : ''}
        jobType={jobRole ? jobRole.jobType : ''}
        date={jobRole ? jobRole.createdAt : undefined}
      />
      <JobDetails job={jobRole} />
      {isMobile ? <Contact /> : <Subscribe />}
      <Footer />
    </Layout>
  )
}

export default JobDetailsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
