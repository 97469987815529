import React from 'react'
import { format } from 'date-fns'
import { Link } from 'gatsby-plugin-react-i18next'
import { useTranslation } from 'react-i18next'
type Props = {
  name: string
  location?: string
  date?: string | undefined
  jobType?: string
}

export const parseDate = (date: string | undefined): Date =>
  new Date(typeof date === 'string' ? date : new Date())

const DetailsHeader: React.FC<Props> = ({ name, location, jobType, date }) => {
  const { t } = useTranslation()

  return (
    <section className="section job-details-header bg-image">
      <div className="container">
        <div className="content-wrapper">
          <h1>{name}</h1>
          <div className="items-wrapper">
            <div className="item-wp">
              <img src="/images/location-dot.svg" alt="img"></img>
              <span>{location}</span>
            </div>
            <div className="item-wp">
              <img src="/images/clock.svg" alt="img"></img>
              <span>{jobType}</span>
            </div>
            <div className="item-wp">
              <img src="/images/calendar.svg" alt="img"></img>
              <span>{format(parseDate(date), 'MMMM d, yyyy')}</span>
            </div>
          </div>
          <Link className="btn" to="/careers" placeholder="">
            <img src="/images/w-arrow-left.svg" alt="img" />
            {t('backToCareers')}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default DetailsHeader
